import { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Auth from "./modules/auth";
import { isAuth } from "./helpers/auth";
import { useUser } from "./store/userState";
import ConsoleLayout from "./shared/ConsoleLayout";

const Protected = ({ children }) => {
  const { setUser } = useUser();
  useEffect(() => {
    setUser(isAuth());
  }, [setUser]);

  return !!isAuth() ? children : <Navigate to="/auth" />;
};

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/console/*"
          element={
            <Protected>
              <ConsoleLayout />
            </Protected>
          }
        />
        <Route path="/auth/*" element={<Auth />} />
        <Route path="*" element={<Navigate replace to="/console" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
