import React from "react";
import { Layout, Menu } from "antd";
import {
  PieChartOutlined,
  UserOutlined,
  BookOutlined,
  FileTextOutlined,
  SolutionOutlined,
  DollarOutlined,
  AuditOutlined,
  ScheduleOutlined,
  ShareAltOutlined,
  PlaySquareOutlined,
} from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";

const { Sider } = Layout;
const { SubMenu } = Menu;
const keys = [
  "dashboard",
  "student",
  "course",
  "batch",
  "collection",
  "modelclass",
  "enrollment",
  "student-fee",
  "batch-fee",
  "resume",
  "schedule",
  "fileshare",
  "video",
];

const ConsoleSider = () => {
  const location = useLocation();
  let selectedKey;
  keys.forEach((i) => {
    const uri = location.pathname.split("/");
    if (uri.indexOf(i) > 0) selectedKey = i;
  });

  return (
    <Sider
      theme="light"
      width={200}
      className="body-height overflow-y-auto appscrollbar disable-select">
      <Menu
        mode="inline"
        defaultSelectedKeys={["0"]}
        selectedKeys={[selectedKey]}
        style={{ height: "100%", borderRight: 0 }}>
        <Menu.Item
          key="dashboard"
          icon={<PieChartOutlined style={{ fontSize: 16 }} />}>
          <Link to="/console/dashboard">Dashboard</Link>
        </Menu.Item>
        <Menu.Item
          key="student"
          icon={<UserOutlined style={{ fontSize: 16 }} />}>
          <Link to="/console/student/list">Students</Link>
        </Menu.Item>
        <Menu.Item
          key="enrollment"
          icon={<SolutionOutlined style={{ fontSize: 16 }} />}>
          <Link to="/console/enrollment">Enrollments</Link>
        </Menu.Item>

        <SubMenu key="academic" icon={<BookOutlined />} title="Academic">
          <Menu.Item key="batch">
            <Link to="/console/batch/list">Batches</Link>
          </Menu.Item>
          <Menu.Item key="course">
            <Link to="/console/course/list">Courses</Link>
          </Menu.Item>
          <Menu.Item key="collection">
            <Link to="/console/collection/list">Collection</Link>
          </Menu.Item>
        </SubMenu>

        <SubMenu key="fee" icon={<DollarOutlined />} title="Fee">
          <Menu.Item key="student-fee">
            <Link to="/console/student-fee">Student Fee</Link>
          </Menu.Item>
          <Menu.Item key="batch-fee">
            <Link to="/console/batch-fee">Batch Fee</Link>
          </Menu.Item>
        </SubMenu>

        <Menu.Item
          key="modelclass"
          icon={<FileTextOutlined style={{ fontSize: 16 }} />}>
          <Link to="/console/modelclass">Model Class</Link>
        </Menu.Item>
        <Menu.Item
          key="resume"
          icon={<AuditOutlined style={{ fontSize: 16 }} />}>
          <Link to="/console/resume">Resumes</Link>
        </Menu.Item>
        <Menu.Item
          key="schedule"
          icon={<ScheduleOutlined style={{ fontSize: 16 }} />}>
          <Link to="/console/schedule">Schedule</Link>
        </Menu.Item>
        <Menu.Item
          key="fileshare"
          icon={<ShareAltOutlined style={{ fontSize: 16 }} />}>
          <Link to="/console/fileshare">File Sharing</Link>
        </Menu.Item>
        <Menu.Item
          key="video"
          icon={<PlaySquareOutlined style={{ fontSize: 16 }} />}>
          <Link to="/console/video">Video Library</Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default ConsoleSider;
