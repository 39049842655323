import { useUser } from "../../store/userState";
import AdminRoutes from "./Admin.Route";
import ManagerRoute from "./Manager.Route";

const ConsoleRoutes = () => {
  const { user } = useUser();
  // 0 for admin
  if (user?.role === 0) return <AdminRoutes />;
  else if (user?.role >= 1) return <ManagerRoute />;

  return <p>Loading...</p>;
};

export default ConsoleRoutes;
