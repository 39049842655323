import { useState } from "react";
import { Button, Card, Form, Input, notification } from "antd";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { authenticate } from "../../helpers/auth";

const Signin = () => {
  const [isSubmitting, setSubmiting] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (values) => {
    setSubmiting(true);
    const url = `${process.env.REACT_APP_API_BASE}/admin/auth/signin`;
    axios
      .post(url, values)
      .then(({ data }) => {
        authenticate(data, () => {
          navigate("/console");
        });
      })
      .catch((error) => {
        setSubmiting(false);
        notification.error({
          message: "Error",
          description: error.response.data.error.message,
          placement: "bottomRight",
        });
      });
  };

  return (
    <div style={{ height: "100vh", display: "grid", placeItems: "center" }}>
      <div>
        <Card
          title="Sign in"
          bordered={false}
          style={{ width: 350, borderRadius: 4 }}
          headStyle={{ textAlign: "center", background: "#f9f9f9" }}>
          <Form layout="vertical" onFinish={handleSubmit}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: "Please enter email" },
                { type: "email", message: "Invalid email" },
              ]}>
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item label="Password" name="password">
              <Input.Password placeholder="Password" />
            </Form.Item>
            <p style={{ paddingBottom: 10, textAlign: "right" }}>
              <Link to="/auth/forgot">Forgot password?</Link>
            </p>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block
                loading={isSubmitting}>
                Sign in
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default Signin;
