import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Signin from "./Signin";
import ForgotPassword from "./ForgotPassword";

const Auth = () => {
  return (
    <Routes>
      <Route path="signin" element={<Signin />} />
      <Route path="forgot" element={<ForgotPassword />} />
      <Route path="/" element={<Navigate replace to="signin" />} />
    </Routes>
  );
};

export default Auth;
