import { Button, Card, Form, Input } from "antd";
import { Link } from "react-router-dom";

const ForgotPassword = () => {
  const handleSubmit = (values) => {
    console.log(values);
  };
  return (
    <div style={{ height: "100vh", display: "grid", placeItems: "center" }}>
      <div>
        <Card
          title="Reset password"
          bordered={false}
          style={{ width: 350, borderRadius: 4 }}
          headStyle={{ textAlign: "center", background: "#f9f9f9" }}>
          <Form layout="vertical" onFinish={handleSubmit}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: "Please enter email" },
                { type: "email", message: "Invalid email" },
              ]}>
              <Input placeholder="Email" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                Reset
              </Button>
              <p style={{ paddingTop: 20, paddingBottom: 0 }}>
                Sign in instead? <Link to="/auth/signin">Sign in</Link>
              </p>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default ForgotPassword;
