import "./style/App.less";
import Router from "./router";
import { notification } from "antd";
import axios from "axios";
import { getToken, signout } from "./helpers/auth";
import { QueryClientProvider, QueryClient } from "react-query";

const queryClient = new QueryClient();
function App() {
  axios.interceptors.request.use(
    function (config) {
      config.headers.Authorization = `Bearer ${getToken()}`;
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      let res = error.response;
      if (res?.status === 401 && res.config && !res.config.__isRetryRequest) {
        signout(() => {
          return (window.location.href = "/");
        });
      }

      if (error.message !== undefined && error.message === "Network Error") {
        notification.error({
          message: "Network error",
          description: "Check your network connection",
          placement: "bottomRight",
        });
      }

      return Promise.reject(error);
    }
  );
  return (
    <QueryClientProvider client={queryClient}>
      <Router />
    </QueryClientProvider>
  );
}

export default App;
