import React from "react";
import { Layout, Typography, Button, Dropdown, Menu, Space } from "antd";
import { useNavigate, Link } from "react-router-dom";
import { SettingOutlined, UserOutlined } from "@ant-design/icons";
import { useUser } from "../../store/userState";
import { signout } from "../../helpers/auth";

const { Header } = Layout;

const ConsoleHeader = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const hindleSignout = () => {
    signout(() => {
      navigate("/");
    });
  };

  const profileMenu = (
    <Menu>
      <Menu.Item key="profile-menu">
        <Link to="/console/user/profile">Profile</Link>
      </Menu.Item>
      <Menu.Item key="signout-menu" onClick={hindleSignout}>
        Sign out
      </Menu.Item>
    </Menu>
  );

  const settingsMenu = (
    <Menu>
      <Menu.Item key="users-menu">
        <Link to="/console/user/list">Users</Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <Header className="header disable-select">
      <Typography.Title level={5} className="logo">
        BrightLearning
      </Typography.Title>

      <Space>
        {user?.role === 0 && (
          <Dropdown
            overlay={settingsMenu}
            placement="bottomRight"
            trigger={["click"]}>
            <Button
              type="text"
              style={{ color: "#1e293b" }}
              icon={<SettingOutlined />}>
              Manage
            </Button>
          </Dropdown>
        )}
        <Dropdown
          overlay={profileMenu}
          placement="bottomRight"
          trigger={["click"]}>
          <Button
            type="text"
            style={{ color: "#1e293b" }}
            icon={<UserOutlined />}>
            {user?.name}
          </Button>
        </Dropdown>
      </Space>
    </Header>
  );
};

export default ConsoleHeader;
