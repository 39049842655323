import { Layout } from "antd";
import ConsoleHeader from "../ConsoleHeader";
import ConsoleSider from "../ConsoleSider";
import Routes from "./Routes";

const { Content } = Layout;

const ConsoleLayout = () => {
  return (
    <Layout>
      <ConsoleHeader />
      <Layout className="body-height overflow-hidden">
        <ConsoleSider />
        <Layout className="content-body overflow-auto" style={{ margin: 0 }}>
          <Content className="site-layout-background" style={{ margin: 0 }}>
            <Routes />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default ConsoleLayout;
