import { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import CenterSpinner from "./CenterSpinner";

const ModelClass = lazy(() => import("../../modules/modelclass"));
const Enrollment = lazy(() => import("../../modules/enrollments"));
const Student = lazy(() => import("../../modules/student"));
const FeeStudent = lazy(() => import("../../modules/fee/student-fee"));
// const FeeBatch = lazy(() => import("../../modules/fee/batch-fee"));
const Schedules = lazy(() => import("../../modules/Schedules"));
const FileSharing = lazy(() => import("../../modules/filesharing"));

const Loader = ({ children }) => (
  <Suspense fallback={<CenterSpinner />}>{children}</Suspense>
);

const ConsoleRoutes = () => {
  return (
    <Routes>
      <Route
        path="modelclass/*"
        element={
          <Loader>
            <ModelClass />
          </Loader>
        }
      />
      <Route
        path="enrollment/*"
        element={
          <Loader>
            <Enrollment />
          </Loader>
        }
      />
      <Route
        path="student/*"
        element={
          <Loader>
            <Student />
          </Loader>
        }
      />
      <Route
        path="student-fee/*"
        element={
          <Loader>
            <FeeStudent />
          </Loader>
        }
      />
      <Route
        path="schedule/*"
        element={
          <Loader>
            <Schedules />
          </Loader>
        }
      />
      <Route
        path="fileshare/*"
        element={
          <Loader>
            <FileSharing />
          </Loader>
        }
      />
      {/* <Route
        path="batch-fee/*"
        element={
          <Loader>
            <FeeBatch />
          </Loader>
        }
      /> */}

      <Route path="*" element={<Navigate to="enrollment" />} />
    </Routes>
  );
};

export default ConsoleRoutes;
