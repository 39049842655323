import { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import CenterSpinner from "./CenterSpinner";
const Dashboard = lazy(() => import("../../modules/dashboard"));
const User = lazy(() => import("../../modules/user"));
const Student = lazy(() => import("../../modules/student"));
const Course = lazy(() => import("../../modules/course"));
const Collection = lazy(() => import("../../modules/collection"));
const Batch = lazy(() => import("../../modules/batch"));
const ModelClass = lazy(() => import("../../modules/modelclass"));
const Enrollment = lazy(() => import("../../modules/enrollments"));
const FeeStudent = lazy(() => import("../../modules/fee/student-fee"));
const FeeBatch = lazy(() => import("../../modules/fee/batch-fee"));
const Schedules = lazy(() => import("../../modules/Schedules"));
const Resume = lazy(() => import("../../modules/resume"));
const FileSharing = lazy(() => import("../../modules/filesharing"));
const Video = lazy(() => import("../../modules/videolibrary"));

const Loader = ({ children }) => (
  <Suspense fallback={<CenterSpinner />}>{children}</Suspense>
);

const ConsoleRoutes = () => {
  return (
    <Routes>
      <Route
        path="dashboard"
        element={
          <Loader>
            <Dashboard />
          </Loader>
        }
      />
      <Route
        path="user/*"
        element={
          <Loader>
            <User />
          </Loader>
        }
      />
      <Route
        path="student/*"
        element={
          <Loader>
            <Student />
          </Loader>
        }
      />
      <Route
        path="course/*"
        element={
          <Loader>
            <Course />
          </Loader>
        }
      />
      <Route
        path="batch/*"
        element={
          <Loader>
            <Batch />
          </Loader>
        }
      />
      <Route
        path="collection/*"
        element={
          <Loader>
            <Collection />
          </Loader>
        }
      />
      <Route
        path="modelclass/*"
        element={
          <Loader>
            <ModelClass />
          </Loader>
        }
      />
      <Route
        path="enrollment/*"
        element={
          <Loader>
            <Enrollment />
          </Loader>
        }
      />
      <Route
        path="student-fee/*"
        element={
          <Loader>
            <FeeStudent />
          </Loader>
        }
      />
      <Route
        path="batch-fee/*"
        element={
          <Loader>
            <FeeBatch />
          </Loader>
        }
      />
      <Route
        path="resume/*"
        element={
          <Loader>
            <Resume />
          </Loader>
        }
      />
      <Route
        path="schedule/*"
        element={
          <Loader>
            <Schedules />
          </Loader>
        }
      />
      <Route
        path="fileshare/*"
        element={
          <Loader>
            <FileSharing />
          </Loader>
        }
      />
      <Route
        path="video/*"
        element={
          <Loader>
            <Video />
          </Loader>
        }
      />
      <Route path="*" element={<Navigate to="dashboard" />} />
    </Routes>
  );
};

export default ConsoleRoutes;
